<template>
  <div class="mod-config">
    <el-page-header @back="$emit('goBack', true)">
      <span slot="content" class="fwb">质量安全监测详情</span>
    </el-page-header>
    <el-divider></el-divider>
    <el-card>
      <el-form class="mt-3" :model="user" ref="user" style="margin-left: 15px;margin-right: 15px;" label-width="100px">
        <el-row :gutter="20" style="width: 100%">
          <el-col :span="6">
            <el-form-item prop="model">
              <span slot="label" class="fwb">分析模型：</span>
              <el-input v-model="user.model" placeholder="分析模型" readonly style="width: 100%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="source">
              <span slot="label" class="fwb">数据来源：</span>
              <el-input v-model="user.source" placeholder="数据来源" readonly style="width: 100%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="category">
              <span slot="label" class="fwb">产品类别：</span>
              <el-input v-model="user.category" placeholder="产品类别" readonly style="width: 100%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="brand" label="">
              <span slot="label" class="fwb">产品品牌：</span>
              <el-input v-model="user.brand" placeholder="产品品牌" readonly style="width: 100%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="width: 100%">
          <el-col :span="6">
            <el-form-item prop="product">
              <span slot="label" class="fwb">产品型号：</span>
              <el-input v-model="user.product" placeholder="产品型号" readonly style="width: 100%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="name">
              <span slot="label" class="fwb">联系人：</span>
              <el-input v-model="user.name" placeholder="联系人" readonly style="width: 100%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="mobile">
              <span slot="label" class="fwb">手机号：</span>
              <el-input
                  v-model="user.mobile"
                  placeholder="手机号"
                  readonly
                  style="width: 100%;"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="enterpriseName">
              <span slot="label" class="fwb">公司名称：</span>
              <el-input
                  v-model="user.enterpriseName"
                  placeholder="公司名称"
                  readonly
                  style="width: 100%;"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-row :gutter="10">
      <el-col :span="16">
        <el-card style="margin-top: 10px;margin-bottom: 10px">
          <div id="line" :style="{width: '100%', height: '400px'}"></div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card style="margin-top: 10px;margin-bottom: 10px;background-color:white">
          <div style="height: 85%">
              <div id="referer" :style="{width: '100%', height: '400px'}"></div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-card>
      <el-row type="flex">
        <el-col :span="12" justify="center">
          <div id="cloud" :style="{width: '700px', height: '500px'}"></div>
<!--      <know-center style="width: 700px;height: 500px;margin-left: -200px"/>-->
        </el-col>
        <el-col :span="12">
          <div id="histogram" :style="{width: '700px', height: '500px'}"></div>
        </el-col>
      </el-row>
    </el-card>
    <el-card style="margin-top: 10px">
      <div class="f20 fwb tac mb-2 mt-2">推荐结果</div>
      <div class="list-box uf uf fwrap-w tal">
        <template v-if="sbrzServiceList.length > 0">
          <div class="list" v-for="(item, index) in sbrzServiceList" :key="index">
            <div @click="goDetail(item.id)">
              <div class="pic-box">
                <img :src="item.logoUrl ? $cons.SERVER_PATH + item.logoUrl : require('@/assets/img/zwtp.png')"/>
              </div>
              <div class="ctx-box">
                <div class="f18 ctx-box-price">
                  <span v-if="item.minPrice !== item.maxPrice && item.minPrice !== -1"><span
                      class="f20">￥</span>{{ (item.minPrice || 0).toFixed(2) }} - {{ (item.maxPrice || 0).toFixed(2) }}</span>
                  <span class="f20" v-else-if="item.minPrice === item.maxPrice && item.minPrice !== -1">
                      ￥{{ (item.minPrice || 0).toFixed(2) }}
                    </span>
                  <span v-else class="f20">面议</span>
                  <span style="font-size: 5px; color: gray"></span>
                </div>
                <div class="dec ctx-link fwb">
                  <span>{{ item.serviceName }}</span>
                </div>
                <div style="padding: 0 14px">
                  <div class="f12">
                    <span class="op-07">服务分类：{{ item.itemName }}</span>
                    <span></span>
                  </div>
                </div>
                <div class="company mb-3 mt-1">
                  <div class="cp uf uf-ac" style="font-size: 10px">
                    <div style="padding: 0 7px;border: 1px solid #007bff;border-radius: 3px;color: #007bff">
                      自营
                    </div>
                    <div class="ml-2"
                         style="padding: 0 7px;border: 1px solid #fe3b25;border-radius: 3px;color: #fe3b25">
                      严选
                    </div>
                    <div class="ml-2"
                         style="padding: 0 7px;border: 1px solid #fe3b25;border-radius: 3px;color: #fe3b25">
                      服务质保
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="tac wi100" style="height: 300px">
            <icon-svg class="mt-3" style="font-size: 200px" name="wushuju"></icon-svg>
            <div class="f14" style="color: #909399">暂无数据</div>
          </div>
        </template>
      </div>
    </el-card>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import 'echarts-gl'
import 'echarts-wordcloud/dist/echarts-wordcloud'
import 'echarts-wordcloud/dist/echarts-wordcloud.min'
import {checkIsNull, sortJson} from '../../../../utils'

export default {
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  components: {
    // KnowCenter
  },
  data() {
    return {
      user: {
        model: '',     //模型
        source: '',    //数据来源
        category: '',  //产品类别
        brand: '',      //产品品牌
        product: '',      //具体型号
        name: '',
        enterpriseName: '',
        mobile: ''
      },
      modelOptions: [{
        value: 'word2vec-lstm',
        label: 'word2vec - lstm'
      }, {
        value: 'word2vec-text_cnn',
        label: 'word2vec - text_cnn'
      }],
      sourceOptions: [{
        value: 'jd',
        label: '京东商城'
      }, {
        value: 'tb',
        label: '淘宝网'
      }],
      posCounts: '', //积极评论数
      negCounts: '', //消极评论数
      pos_percent: 0,
      neg_percent: 0,
      negWords: [],  //词云图
      histogramLabel: [],
      histogramData: [],
      linexKey: [],
      linePos: [],
      lineNeg: [],
      sbrzServiceList: [],
      recommendService: '',
      mainId: '',
      corporatePortraitList: [],
      cicrleConfig: {
        data: [
          {
            name: '周口',
            value: 55
          },
          {
            name: '南阳',
            value: 120
          },
          {
            name: '西峡',
            value: 78
          },
          {
            name: '驻马店',
            value: 66
          },
          {
            name: '新乡',
            value: 80
          }
        ]
      }
    }
  },
  computed: {
    keywordsConfig () {
      let keywords = []
      this.negWords.forEach(item => {
        keywords.push({name: `<span style="color: black;font-weight: bold;margin-left: 5px">${item.name}</span>`,value: item.value})
      })
      return {
        data: [...keywords]
      }
    }
  },
  mounted() {
    // this.initPieData()
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl(`${this.$api.TECH.WORD2VEC.INFO}/${this.id}`),
        method: 'get',
        params: this.$http.adornParams({})
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.user = data.data
          let model = this.modelOptions.find(item => {
            return item.value = this.user.model
          })
          this.user.model = model.label
          let source = this.sourceOptions.find(item => {
            return item.value = this.user.source
          })
          this.user.source = source.label

          let result = JSON.parse(data.data.result)
          this.posCounts = result.pos_attr_count
          this.negCounts = result.neg_attr_count
          let all_count = result.pos_attr_count + result.neg_attr_count
          this.pos_percent = (this.posCounts/all_count*100).toFixed(2)
          this.neg_percent = (this.negCounts/all_count*100).toFixed(2)
          this.negWords = eval(result.neg_words)
          this.histogramData = eval(result.word_percent)
          this.histogramData.forEach((item, index) => {
            if (index < 7) {
              this.histogramLabel.push(item.groupId)
            }
          })
          let pos = sortJson(eval('(' + result.pos_months + ')'))
          let neg = sortJson(eval('(' + result.neg_months + ')'))
          for (var key in neg) {
            this.linexKey.push(key)
          }
          this.linexKey.forEach(item => {
            this.linePos.push(pos[item])
            this.lineNeg.push(neg[item])
          })
          this.histogram()
          this.initPieData()
          this.getResult()
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    getResult() {
      this.$http({
        url: this.$http.adornUrl(this.$api.RECOMMENDED.SUBMIT),
        method: 'POST',
        params: this.$http.adornParams({
          mobile: this.user.mobile,
          companyName: this.user.enterpriseName,
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          if (data.dataMap !== undefined) {
            this.recommendService = data.dataMap.recommendService
            this.mainId = data.dataMap.recommend.id
            this.userInfoList = data.dataMap.userInfoList
            this.getStats()
          }
        }
      })
    },
    // 推荐算法 进程
    getStats() {
      this.$http({
        url: this.$http.adornUrl(this.$api.RECOMMENDED.STATS),
        method: 'post',
        params: this.$http.adornParams({
          mobile: this.user.mobile
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          let mobile = this.user.mobile
          let type = data[mobile]
          if (type === 'CHECK_ING') {
            this.getStats()
          } else {
            this.toResult()
          }
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    // 推荐算法弹框
    toResult() {
      let list = [
        this.user.enterpriseName,
        this.user.mobile,
        this.mainId,
        this.user.enterpriseId
      ]
      this.corporatePortraitList = list
      this.getList()
    },
    // 获取企业画像接口
    getList() {
      let dataList = this.corporatePortraitList
      // if(dataList.length!== 0){
      //    dataList =  dataList.split(',')
      //
      // }else{
      //   dataList = []
      // }
      this.$http({
        url: this.$http.adornUrl(this.$api.RECOMMENDED.LIST),
        method: 'post',
        params: this.$http.adornParams({
          mobile: dataList.length > 0 ? dataList[1]: null,
          mainId: dataList.length > 0 ? dataList[2]: null,
          enterpriseId: dataList.length > 0 ? dataList[3]: null,
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let enterpriseLabel =data.dataList.enterpriseLabel.slice(0, 4)
          let recommendService =data.dataList.recommendService
          this.sbrzServiceList = recommendService
          this.sbrzServiceList.forEach(item => {
            if (checkIsNull(item.minPrice)) {
              let min = item.skuList[0].price
              item.skuList.forEach(sku => {
                if(sku.price < min){
                  min = sku.price
                }
              })
              item.minPrice = min
            }
          })
          this.sbrzServiceList.forEach(item=>{
            item.id=item.serviceId
          })
          this.companyLabels = []
          enterpriseLabel.forEach((item, index)=>{
            if (index < 4) {
              this.companyLabels.push(item.labelName)
            }
          })
          this.loading = false
        } else {
          this.$message.error(data.msg)
          this.loading = false
        }
      })
    },
    histogram() {
      // let myChart1 = echarts.init(document.getElementById('referer'))
      // myChart1.setOption({
      //   title: {
      //     text: '评论两极占比',
      //     left: 'left'
      //   },
      //   color: [
      //     '#03a9f4',
      //     '#97e2f5',
      //     '#ff9800',
      //     '#fcebad'
      //   ],
      //   dataset: [
      //     {
      //       source: [
      //         ['label', 'Sales', 'index'],
      //         ['pos', this.posCounts, 1],
      //         ['neg', this.negCounts, 1],
      //         ['neg2', this.negCounts, 2],
      //         ['pos2', this.posCounts, 2]
      //
      //       ]
      //     },
      //     {
      //       transform: {
      //         type: 'filter',
      //         config: {dimension: 'index', value: 1}
      //       }
      //     },
      //     {
      //       transform: {
      //         type: 'filter',
      //         config: {dimension: 'index', value: 2}
      //       }
      //     }
      //   ],
      //   series: [
      //     {
      //       type: 'pie',
      //       radius: ['30%', '60%'],
      //       center: ['25%', '50%'],
      //       datasetIndex: 1,
      //       hoverAnimation: false,
      //       label: {
      //         normal: {
      //           show: false
      //         }
      //       }
      //     },
      //     {
      //       type: 'pie',
      //       radius: ['30%', '60%'],
      //       center: ['75%', '50%'],
      //       datasetIndex: 2,
      //       hoverAnimation: false,
      //       label: {
      //         normal: {
      //           show: false
      //         }
      //       }
      //     },
      //
      //   ]
      // })

      let myChart2 = echarts.init(document.getElementById('line'), 'light')
      myChart2.setOption({
            title: {
              text: '两极评论走势曲线图'
            },
            color: ['#80FFA5',
              '#FF0087'],
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              }
            },
            legend: {
              data: ['积极评论', '消极评论'],
              textStyle: {//图例文字的样式
                color: '#ccc'
              },
              y: '5%'
            },
            toolbox: {
              feature: {}
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                boundaryGap: false,
                data: this.linexKey
              }
            ],
            yAxis: [
              {
                type: 'value'
              }
            ],
            series: [
              {
                name: '积极评论',
                type: 'line',
                stack: 'Total',
                smooth: true,
                lineStyle: {
                  width: 0
                },
                showSymbol: false,
                areaStyle: {
                  opacity: 0.8,
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: 'rgb(128, 255, 165)'
                    },
                    {
                      offset: 1,
                      color: 'rgb(1, 191, 236)'
                    }
                  ])
                },
                emphasis: {
                  focus: 'series'
                },
                data: this.linePos
              },
              {
                name: '消极评论',
                type: 'line',
                stack: 'Total',
                smooth: true,
                lineStyle: {
                  width: 0
                },
                showSymbol: false,
                areaStyle: {
                  opacity: 0.8,
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: 'rgb(0, 221, 255)'
                    },
                    {
                      offset: 1,
                      color: '#FF0087'
                    }
                  ])
                },
                emphasis: {
                  focus: 'series'
                },
                data: this.lineNeg
              }
            ]
          }
      )

      let myChart3 = echarts.init(document.getElementById('cloud'), 'light')
      var maskImage = new Image()
      maskImage.src = require('@/assets/img/dog.png')
      myChart3.setOption({
        title: {
          text: '消极评论关键词云图',
          left: 'left'
        },
        backgroundColor: '#ffffff',
        tooltip: {
          show: false
        },
        series: [{
          type: 'wordCloud',
          gridSize: 1,
          sizeRange: [12, 55],
          rotationRange: [-45, 0, 45, 90],
          imageShape: maskImage,
          textStyle: {
            normal: {
              color: function () {
                return 'rgb(' +
                    Math.round(Math.random() * 255) +
                    ', ' + Math.round(Math.random() * 255) +
                    ', ' + Math.round(Math.random() * 255) + ')'
              }
            }
          },
          left: 'center',
          top: 'center',
          // width: '96%',
          // height: '100%',
          right: null,
          bottom: null,
          // width: 300,
          // height: 200,
          // top: 20,
          data: this.negWords
        }]
      })

      let myChart4 = echarts.init(document.getElementById('histogram'), 'light')

      // 绘制图表
      myChart4.setOption({
        title: {
          text: '消极评论关键词占比',
          left: 'left'
        },
        color: ['#3498db','#2ecc71'],
        xAxis: {
          data: this.histogramLabel
        },
        yAxis: {
          type: 'value'
        },
        dataGroupId: '',
        animationDurationUpdate: 500,
        series: {
          type: 'bar',
          id: 'sales',
          label: {
            normal: {
              show: true,
              position: 'insideRight'
            }
          },
          data: this.histogramData,
          universalTransition: {
            enabled: true,
            divideShape: 'clone'
          }
        }
      })
    },
    initPieData () {
      let chartDom = document.getElementById('referer')
      let pieChart = echarts.init(chartDom)
      let option = {
        title: {
          text: '两极评论占比',
          // subtext: 'Fake Data',
          left: 'center'
        },
        color: ['#3498db','#f39c12'],
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: '评论占比',
            type: 'pie',
            radius: '75%',
            data: [
              { value: this.pos_percent, name: '积极评论' },
              { value: this.neg_percent, name: '消极评论' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      pieChart.setOption(option)

    },
    goDetail(id) {
      this.$router.push({name: 'service', query: {id, isProvider: false}})
    }
  }
}
</script>


<style scoped>

#rectangle1 {
  width: 20px;
  height: 12px;
  background: #03a9f4;
}

#rectangle2 {
  width: 20px;
  height: 12px;
  background: #ff9800;
}
</style>
